import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">       
        
        {/* <li><a href="https://wshibatoken.gitbook.io/wshiba/wshiba-tokenomics" target="_blank">Tokenomics</a></li> */}
					    <li><a href="https://daddydoge-defi.gitbook.io/docs/treasure-map/" target="_blank">Roadmap</a></li>
					    <li><a href="https://twitter.com/DaddyDogeCoin_" target="_blank">Twitter</a></li>
					    <li><a href="https://t.me/daddydogenews" target="_blank">Channel</a></li>
					    <li><a href="https://t.me/daddydogecoin_official" target="_blank">Chat</a></li>
					    <li><a href="mailto:support@daddydoge.net" target="_blank">support@daddydoge.net</a></li>
      </ul>
    </nav>
  );
}

export default FooterNav;