import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Purchase = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  useEffect(() => {

  });

  return (

    //
    <section
      {...props}
    //className={outerClasses}
    >

      <div class="container-purchase" style={{paddingTop:"0px"}}>
        <section>
          <div class="section-heading text-center">
            {/* <div class="dream-dots justify-content-center wow fadeInUp" >
              <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
            </div> */}
            <h2 class="wow fadeInUp">DADDY TREASURE MAP</h2>
            {/* <p class="wow fadeInUp">The road come to the moon
              should be planned carefully</p> */}
          </div>

          <div class="container">
            <div class="row">
              <div class="timeline-split">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="timeline section-box-margin">
                  <div class="block block-left">
                             {/*<h3>Platform Development Starts</h3>
                            <span class="date">May 29, 2020</span> <span class="between">to</span> <span class="date">Jul 19, 2021</span> */}
                            <span class="date">Phase 1: Find a way back to Earth</span> 
                            <ul>
                                <li><span>Using "Ki" power to open a Dimension Portal to come back to Earth. (* That why 50% Daddy Doge burned)</span></li>
                                <li><span>Research Market Situation</span></li>
                                <li><span>Hire a Team to restore Daddy Doge power</span></li>
                            </ul>
                    </div>
                    <div class="block block-right mt-30">
                            {/* <h3>APP and CEX</h3>
                            <span class="date">Jul 20, 2021</span> <span class="between">to</span> <span class="date">Sep 23, 2021</span> */}
                            <span class="date">Phase 2: Plan to support his Baby Doge</span> 
                            <ul>
                                <li><span>Research Daddy Doge tokenomics</span></li>
                                <li><span>Convert Daddy's tricks to Smart Chain Contract Code</span></li>
                                <li><span>Design Daddy Doge's website</span></li>
                                <li><span>Complete Daddy Doge Whitepaper</span></li>
                                <li><span>Pre-Sale with the first target of 5000 holders</span></li>
                                <li><span>Listing on PancakeSwap Exchange</span></li>
                                <li><span>Support Baby Doge son of Daddy Doge</span></li>
                            </ul>
                        </div>
                        <div class="block block-left mt-30">
                            {/* <h3>Crypto Wallet Release</h3>
                            <span class="date">Oct 08, 2021</span> <span class="between">to</span> <span class="date">Nov 16, 2021</span> */}
                             <span class="date">Phase 3: Awakening power of the Daddy</span> 
                            <ul>
                                <li><span>Attract the attention of all the Doge coin communities</span></li>
                                <li><span>Listing CoinGecko</span></li>
                                <li><span>Listing CoinMarketCap</span></li>
                                <li><span>Reach 10,000 telegram members.</span></li>
                                <li><span>Reach 10,000 holders</span></li>
                                <li><span>Release Auto-DEX & Audit by 3rd part</span></li>
                                <li><span>Support Baby Doge son of Daddy Doge</span></li>
                            </ul>
                        </div>
                        <div class="block block-right mt-30">
                            {/* <h3>Release Bank &amp; Cards Phase</h3>
                            <span class="date">Dec 28, 2018</span> <span class="between">to</span> <span class="date">2022</span> */}
                            <span class="date">Phase 4: Jump on the Elon Musk's Rocket</span> 
                            <ul>
                                <li><span>Reach 100,000 holders</span></li>
                                <li><span>Reach  100,000 telegram members</span></li>
                                <li><span>Marketing Campaign</span></li>
                                <li><span>Listings on major Centralized Exchange</span></li>
                                <li><span>Release Meme NFT Tool</span></li>
                                <li><span>Support Baby Doge son of Daddy Doge</span></li>
                            </ul>
                        </div>
                        <div class="block block-left mt-30">
                            {/* <h3>Crypto Wallet Release</h3>
                            <span class="date">Oct 08, 2021</span> <span class="between">to</span> <span class="date">Nov 16, 2021</span> */}
                             <span class="date">Phase 5: Go to the Moon with his Baby Doge</span> 
                            <ul>
                                <li><span>200,000 holders</span></li>
                                <li><span>Upgrade website & release new Whitepaper for future development</span></li>
                                <li><span>Airdrop Baby Doge Coin & Telsa car worth of $100,000 after surpass 100M marketcap</span></li>
                                <li><span>Release Dogallet - Crypto wallet with multi-functions</span></li>
                                <li><span>Support Baby Doge son of Daddy Doge</span></li>
                            </ul>
                        </div>
                        <div class="block block-right mt-30">
                            {/* <h3>Release Bank &amp; Cards Phase</h3>
                            <span class="date">Dec 28, 2018</span> <span class="between">to</span> <span class="date">2022</span> */}
                            <span class="date">Phase 6: Discuss the plan with his son for Mars mission</span> 
                            <ul>
                                <li><span>TBA</span></li>
                            </ul>
                        </div>
                        <div class="circle"></div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

Purchase.propTypes = propTypes;
Purchase.defaultProps = defaultProps;

export default Purchase;
