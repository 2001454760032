import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import f_1 from '../../assets/images/f2/1.png'
import f_2 from '../../assets/images/f2/2.png'
import f_3 from '../../assets/images/f2/3.png'
import f_4 from '../../assets/images/f2/4.png'
import f_5 from '../../assets/images/f2/5.png'
const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Purchase = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  useEffect(() => {

  });

  return (

    //
    <section
      {...props}
    //className={outerClasses}
    >

<div class="container-purchase">
        <section class="our_services_area section-padding-100-0 clearfix" id="services">
        <div class="section-heading text-center">
            {/* <div class="dream-dots justify-content-center wow fadeInUp" >
              <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
            </div> */}
            <h2 class="wow fadeInUp">Features</h2>
            {/* <p class="wow fadeInUp">The road come to the moon
              should be planned carefully</p> */}
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-6" style={{paddingBottom:"12px"}}>
                <div class="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="service_icon">
                    <center><img alt="" src={f_1}></img></center>
                  </div>
                  <h6>Liquidity Backed By Code</h6>
                  <p class="service_p">There will be 3% fee added to the project's Liquidity Pool to build a solid foundation for the growth of Ants.Finance to increase liquidity after each transaction. This feature takes source from Ant community - the high level of social insects, living in an organized manner to cooperate and work effectively, together building larger and stronger ant nests.</p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-6">
                <div class="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="service_icon">
                  <center><img alt="" src={f_2}></img></center>
                  </div>
                  <h6>Pumping Pool</h6>
                  <p class="service_p">After each transaction, there are 3% BNB adding to Pumping Pool which will be used to buyback & burn ANTF weekly. Pumping Pool is a unique feature of Ants.Finance to create a constant driving force to accelerate the development of Ants.Finance.</p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-6" style={{paddingBottom:"12px"}}>
                <div class="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="service_icon">
                  <center><img alt="" src={f_3}></img></center>
                  </div>
                  <h6>Holder Passive Reward</h6>
                  <p class="service_p">There will be 2% of fee burned after each transaction, which makes the percentage of ANTF tokens which you hold keep increasing due to decrease in Total Supply. Ants.Finance is built to show the solidarity - fairness - sharing of Ant community to the World.
                      Pumping Pool: After each transaction, there are 3% BNB adding to Pumping Pool which will be used to buyback & burn ANTF weekly. Pumping Pool is a unique feature of Ants.Finance to create a constant driving force to accelerate the development of Ants.Finance.</p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-6" style={{paddingBottom:"12px"}}>
                <div class="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="service_icon">
                  <center><img alt="" src={f_4}></img></center>
                  </div>
                  <h6>Community Driven</h6>
                  <p class="service_p">Wdogecoin and wShiba will increase transparency in transactions by providing detailed and real-time logs of all transactions. We will be as transparent as possible while remaining competitive</p>
                </div>
              </div>
              <div class="col-12">
                <div class="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="service_icon">
                  <center><img alt="" src={f_5}></img></center>
                  </div>
                  <h6>Ants Communication</h6>
                  <p class="service_p">ANTF is the first DeFi project owning multi-level marketing based on blockchain technology - 3-Level BlockChain Referral. Ant Social Lifestyle leading to the ability to communicate, exchange information and protect each other is an inspiration for Ants.Finance. That's why we create a comprehensive marketing network run by the ANTF community itself, which enhances social cohesion in the community.</p>
                </div>
              </div>
            </div>
          </div>

        </section>
      </div>
    </section>
  );
}

Purchase.propTypes = propTypes;
Purchase.defaultProps = defaultProps;

export default Purchase;
