import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'The Return of Daddy Doge',
    paragraph: 'Daddy Doge has come back after a long time carried away into a crack in space-time. He is Father of Baby Doge. After coming back, Daddy Doge is extremely surprised and very proud of his little son Baby Doge’s success. By learning a few clever tricks from his respected father, Baby Doge has become very impressive and famous. Daddy Doge will not leave his beloved child alone, Baby Doge\'s Father - THE DADDY DOGE came to accompany him. Possessing many preeminent features, DADDY DOGE COIN returned to show the lofty responsibility of a father, will demonstrate to the World \"Who is Doge Daddy?\". Simple love, strong responsibility and watch your Daddy rocking. '
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container" style={{fontSize:'16px'}}>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12">
                  Doge Snack
                  </h3>
                <p className="m-0">
                get a bit of rewards every day like eating snack. There will be 2% of fee redistributed to all holders after each transaction.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/DogeSnack.gif')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12">
                Dimension Portal
                  </h3>
                <p className="m-0">
                50% Daddy Doge will be allocated into Dimension Portal, Daddy Doge will be allocated there without stopping after each transaction due to 1% of fee redistributed to all holders, and Dimension Portal also count as a holder. The portal to another dimension constantly sucks Daddy Doge Coin in. Imagine one day in the future, most of the Daddy Doge Coins will be sucked in and can't come back to the Earth. You - yourself, who has been patiently holding Daddy Doge Coin will become one of Daddy Doge owners & become The Chosen-Daddy!
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/DimensionPortal.gif')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12">
                Daddy Responsibility
                  </h3>
                <p className="m-0">
                Take care of our Baby Doge - Reward our Top richers - Show the responsibility of the Father. There will be 2% for Tax, 1% for the usage to buy Baby Doge & distribute Baby Doge coin to Top 20 Richers every month (at the end of each month). 1% for marketing promotion. 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/DaddyResponsibility.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12">
                Daddy Doge Backup
                  </h3>
                <p className="m-0">
                There will be 2% of fee added to liquidity pool after each transaction. That make Daddy Doge Coin floor price more stable.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/DaddyDogeBackup.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;