import React, { useState, useRef, useEffect } from 'react';
import {Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
// import Logo from './partials/Logo';
import '../../assets/css/header.css'
import logo from '../../assets/images/DaddyDoge.png'
import Web3 from 'web3'
import { SETTINGS } from '../../config/config'
const Header = ({

}) => {

  const [isActive, setIsactive] = useState(false);
  const nav = useRef(null);
  const hamburger = useRef(null);

  const openMenu = () => {

  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }

  const tokenAddress = SETTINGS.TOKEN_CONTRACT_ADDRESS;
  const tokenSymbol = SETTINGS.SYMBOL_;
  const tokenDecimals = SETTINGS.DECIMAL;
  const tokenImage = 'https://daddydoge.net/static/media/DaddyDoge.5b6ab408.png';

   // wasAdded is a boolean. Like any RPC method, an error may be thrown.
  const addMetaMask = async () => {
    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });
    
      if (wasAdded) {
        console.log('Thanks for your interest!');
      } else {
        console.log('Your loss!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  function showDiv(Div) {
    const x = document.getElementById(Div);
    if (!x) {
      return
    }
    if (document.body.clientWidth < 1000) {
      const toggleX = document.getElementById(`toggle-line-x`);
      const toogleLine = document.getElementById(`toggle-line`);
      if (x.style.display === "none") {
        x.style.display = "flex";
        x.style.left = "0 !important;"
        x.style.transitionProperty = "all";
        x.style.transitionDuration = "0.4s";
        x.style.transitionTimingFunction = "ease";
        x.style.transitionDelay = "0s";


        toggleX.style.display = "block";
        toogleLine.style.display = "none"
      } else {
        x.style.display = "none";
        toggleX.style.display = "none";
        toogleLine.style.display = "block"
      }
    }

  }

  return (
    <header>
      {/* src={require('./../../assets/images/features-split-image-01.png')} */}
      <div class="header-main">
        <div class="header-nav">
          <div class="left-toggle"></div>
          <div class="header-nav-toggle">
            <a href="#" class="navbar-toggle" data-menu-toggle="header-menu">
              <div class="toggle-line" id="toggle-line" onClick={() => showDiv("header_navbar")} >
                <span></span>
              </div>
              <div class="toggle-line-x" id="toggle-line-x" onClick={() => showDiv("header_navbar")} >
              </div>
            </a>
          </div>
        </div>
        <div class="header-navbar" id="header_navbar">

          <nav class="header-menu" id="header-menu">
            <ul class="menu">
              <li>  <a href="/" class="logo-link">
                <img class="logo-dark" src={logo} />
              </a></li>
              <li class="menu-item"><a class="menu-item-a" href="/">Home</a></li>
              <li class="menu-item" style={{marginRight:'10px'}}><a class="menu-item-a" href="https://daddydoge-defi.gitbook.io/docs/" target="_blank">Whitepaper</a></li>
              {/* <li class="menu-item"><a class="menu-item-a" href="/airdrop" target="_blank"> Airdrop </a></li> */}
              <li class="menu-item"><a class="menu-item-a" href="https://t.me/daddydogecoin_official" target="_blank">Telegram</a></li>
              <li class="menu-item"><a class="menu-item-a" href="https://twitter.com/DaddyDogeCoin_" target="_blank"> Twitter </a></li>
              <li class="menu-item"><a class="menu-item-a" href="https://daddydoge-defi.gitbook.io/docs/treasure-map/" target="_blank">Roadmap</a></li>
            </ul>
            <ul class="ul-btn-truswallet">
              <li><a style={{ backgroundColor: '#16369c' }} href="javascript:void(0)" onClick={() => addMetaMask()} class="btn-truswallet"><span>Add to Metamask</span>
              </a>
              </li>
            </ul>
            <ul class="ul-btn-truswallet">
              <li><a href="https://link.trustwallet.com/open_url?coin_id=56&url=https://daddydoge.net" class="btn-truswallet" ><span>Open in Trust Wallet</span></a></li>
            </ul>
          </nav>
          <div class="header-navbar-overlay" id="header-navbar-overlay" onClick={() => showDiv("header_navbar")}></div>
        </div>

      </div>
    </header>
  );
}

export default Header;
