import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Web3 from 'web3'
import ReactTwitterFollowButton from 'react-twitter-follow-button';

import '../../assets/css/purchase.css'
import '../../assets/css/style.css'

import { Form, Button } from 'react-bootstrap';
import { notification } from 'antd';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Purchase = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'purchase section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'purchase-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  const [symbol, setSymbol] = useState('');
  const [amount, setAmount] = useState(0.01);
  const [price, setPrice] = useState(0);
  const [balance, setBalance] = useState(0);
  const [claimAmount, setClaimAmount] = useState(0);
  const [claimCost, setClaimCost] = useState(0);
  const [f1Percent, setF1Percent] = useState(0);
  const [f2Percent, setF2Percent] = useState(0);
  const [f3Percent, setF3Percent] = useState(0);
  const onAmountChange = (event) => {
    setAmount(event.target.value)
  }

  const onPurchase = async () => {
    if (!amount || isNaN(amount) === true) {
      notification.error({ message: "Amount must be number" })
      return
    }
    if (!props.account) {
      return;
    }

    const { saleToken } = props
    const savedReferral = localStorage.getItem('referral')
    const referral = savedReferral ? savedReferral : props.account
    const bnbWeiBN = Web3.utils.toWei(amount.toString())
    const saleAmount = await props.saleToken.methods.saleAmount().call();
    await saleToken.methods.buy(referral).send({ from: props.account, value: bnbWeiBN.toString() })
  }

  const onClaim = async () => {
    if (!props.account) {
      return;
    }

    const { saleToken } = props
    const savedReferral = localStorage.getItem('referral')
    const referral = savedReferral ? savedReferral : props.account
    const claimCost = await saleToken.methods.claimCost().call()
    await saleToken.methods.claim(referral).send({ from: props.account, value: claimCost.toString() })
  }

  const onCopy = () => {
    const el = document.createElement('textarea');
    el.value = getRefUrl();
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  const getClaimAmount = async () => {
    const { saleToken } = props
    if (!saleToken) return
    const amount = await saleToken.methods.claimAmount().call();
    console.log('claim amount ', amount);
    setClaimAmount(Number(amount)/1e9)

  }

  const getRefUrl = () => {
    const baseUrl = window.location.host + '/'
    return props.account ? baseUrl + props.account : 'not found'
  }

  const loadSymbol = async () => {
    if (!props.tokenInfo) return
    const symbol = await props.tokenInfo.methods.symbol().call()
    setSymbol(symbol)
  }

  const loadPrice = async () => {
    if (!props.saleToken) return
    const price = await props.saleToken.methods.price().call()
    setPrice(price.toString())
  }

  const loadBalance = async () => {
    if (!props.tokenInfo || !props.account) return
    const balance = await props.tokenInfo.methods.balanceOf(props.account).call()
    setBalance(balance.toString())
  }

  const getTokenByBNB = (bnb) => {
    const token = (bnb / Number(Web3.utils.fromWei(price.toString())))
    return formatNum(token)
  }

  const loadRefClaimAmount = async () => {
    if (!props.saleToken) return
    const claimCost = await props.saleToken.methods.refClaimAmount().call();
    setClaimCost(Number(claimCost)/1e9)

  }

  const loadF1Persent = async () => {
    if (!props.saleToken) return
    const f1Percent = await props.saleToken.methods.f1Percent().call();
    setF1Percent(f1Percent.toString())

  }

  const loadF2Persent = async () => {
    if (!props.saleToken) return
    const f2Percent = await props.saleToken.methods.f2Percent().call();
    setF2Percent(f2Percent.toString())

  }

  const loadF3Persent = async () => {
    if (!props.saleToken) return
    const f3Percent = await props.saleToken.methods.f3Percent().call();
    setF3Percent(f3Percent.toString())

  }


  useEffect(() => {
    const referral = props.match.params.referral
    const isAddress = Web3.utils.isAddress(referral)
    if (isAddress && referral != localStorage.getItem('referral')) {
      localStorage.setItem('referral', referral)
    }

    loadSymbol()
    loadPrice()
    loadBalance()
    getClaimAmount()
    loadRefClaimAmount()
    loadF1Persent()
    loadF2Persent()
    loadF3Persent()
  });

  const fromWei = (_number) => {
    const num = Web3.utils.fromWei(_number.toString())
    return formatNum(num)
  }

  const formatNum = (num) => {
    return new Intl.NumberFormat('en-AU', { maximumSignificantDigits: 9 }).format(num)
  }

  return (

    //
    <section
      {...props}
    //className={outerClasses}
    >
      <div className="centerContent">
        <div className="selfCenter">
          <ReactTwitterFollowButton twitterAccount="DaddyDogeCoin_" showLarge={true} showName={false} showCount={false} />
        </div>
      </div>
      <div class="container-purchase">
        <div class="div-purchase">
          <Form>
            <Form.Text>
              <div class="title">
                <h4 className="pupple-text"> PRESALE SALE </h4>
                <p className="pupple-text"> Your Balance: {formatNum(Number(balance)/1e9)} {symbol} </p>
              </div>
            </Form.Text>
            <Form.Group class="form-group">
              <div>
                <span>Enter BNB</span>
                <Form.Control onChange={(event) => onAmountChange(event)} type="input" lang="en-AU" defaultValue={amount.toLocaleString("en-AU")} class="input-number" />

              </div>
              <div>
                <Button variant="primary" onClick={() => onPurchase()} class="button-action">
                  Purchase
                </Button>
              </div>

            </Form.Group>
            <Form.Group class="form-group">
              <div class="claim">
                <Button variant="primary" onClick={() => onClaim()} class="button-claim" >
                  Claim {formatNum(claimAmount)} {symbol}
                </Button>
              </div>
            </Form.Group>
            <div className="hint-text">
              <p>(*) Price: 1BNB = {getTokenByBNB(1)} {symbol}</p>
              <p>(*) Minimum purchase 0.01 BNB - Max purchase 5 BNB</p>
              <p>(*) Let’s share {symbol} to get commission: level 1 - get {f1Percent}%; level 2 - {f2Percent}%; level 3 - {f3Percent}%. Not limit commission for you to get. Liquid immediately on PancakeSwap</p>
              <p>(*) Adding logo on Trust Wallet when having enough 2500 holders.</p>
            </div>
          </Form>
        </div>
        <div class="div-referal">
          <Form>
            <Form.Text>
              <div class="title">
                <h4 className="pupple-text"> Get your Referral link </h4>
              </div>
            </Form.Text>
            <Form.Group>
              <Form.Control type="text" readOnly={true} value={getRefUrl()} />
              <Button variant="primary" onClick={() => onCopy()}>
                Copy
              </Button>
            </Form.Group>
            <div className="hint-text">
              <p>(*) Each Referral friend claims {formatNum(claimCost)} {symbol}.</p>
              <p>(*) Let’s share to get {(Number(claimCost) / Number(claimAmount)) * 100}% all of Claim & {f1Percent}% of Purchase at Level 1; {f2Percent}% of Purchase at Level 2; {f3Percent}% of Purchase at Level 3.</p>
              <p>(*) There is no limit bonus for Referral.</p>
            </div>
            <div className="hint-text">
            <iframe src='//acceptable.a-ads.com/1705416'
              data-aa='1705416'
              frameborder='0'
              width="100%"
              height="100%"
            />
            </div>
            <div className="hint-text">
            <iframe src='//acceptable.a-ads.com/1705478'
              data-aa='1705478'
              frameborder='0'
              width="100%"
              height="100%"
            />
            </div>
            
          </Form>
        </div>
      </div>
    </section>
  );
}

Purchase.propTypes = propTypes;
Purchase.defaultProps = defaultProps;

export default Purchase;
