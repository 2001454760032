import React, { useState, useEffect } from 'react';
// import sections
import CountDown from '../components/sections/CountDown';
import Purchase from '../components/sections/Purchase';

import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Feautures from '../components/sections/Feautures';
import RoadMap from '../components/sections/RoadMap';

import Cta from '../components/sections/Cta';
import Web3 from 'web3'
import { notification } from 'antd'
import * as saleContractJson from '../utils/saleContract.json'
import * as tokenContractJson from '../utils/tokenContract.json'
import 'antd/dist/antd.css';
import { SETTINGS } from "../config/config"
import { random } from 'lodash'

const Home = (props) => {
  // const [web3, setWeb3] = useState(false);
  const [account, setAccount] = useState(null);
  const [web3, setWeb3] = useState(null)
  const [saleToken, setSaleToken] = useState(null)
  const [tokenInfo, setTokenInfo] = useState(null)
  const [isAlert, setIsAlert] = useState(false)

  const nodes = [
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
    "https://bsc-dataseed2.defibit.io/",
    "https://bsc-dataseed3.defibit.io/"
  ]

  const getNodeUrl = () => {
    const randomIndex = random(0, nodes.length - 1)
    return nodes[randomIndex]
  }


  let web3js = new Web3(getNodeUrl());

  let saleContract, tokenContract = null;
  const initContract = (web3js) => {
    if (!web3js) {
      return;
    }
    //console.log('init contract');
    saleContract = new web3js.eth.Contract(saleContractJson.abi, SETTINGS.SALE_CONTRACT_ADDRESS);
    tokenContract = new web3js.eth.Contract(tokenContractJson.abi, SETTINGS.TOKEN_CONTRACT_ADDRESS);

  }

  useEffect(() => {

    if (window.ethereum) {
      // setWeb3(new Web3(window.ethereum))
      web3js = new Web3(window.ethereum)
      try {
        window.ethereum.enable().then(function () {
          web3js.eth.getAccounts((error, accounts) => {
            if (error) {
              console.log(error);
              setAccount(null)
            } else {
              if (!account) {
                setAccount(accounts[0])
                setWeb3(web3js)
                initContract(web3js)
                setSaleToken(saleContract)
                setTokenInfo(tokenContract)
              }
            }
          });
          // User has allowed account access to DApp...
        });
      } catch (e) {
        // User has denied account access to DApp...
      }
    }
    // Legacy DApp Browsers
    else if (window.web3) {
      // setWeb3(new Web3(window.web3.currentProvider))
      web3js = new Web3(window.web3.currentProvider)
      if (!web3) {
        setWeb3(web3js)
        initContract(web3js)
        setSaleToken(saleContract)
        setTokenInfo(tokenContract)
      }
    }
    //  Non-DApp Browsers
    else {
      try {
        setWeb3(web3js)
        initContract(web3js)
        setSaleToken(saleContract)
        setTokenInfo(tokenContract)
      } catch (error) {
        console.log(error);
      }

    }
    //console.log('props', props)
  });


  return (
    <>
      <CountDown className="illustration-section-01" tokenInfo={tokenInfo} />
      <Purchase {...props} saleToken={saleToken} tokenInfo={tokenInfo} web3={web3} account={account} className="illustration-section-01" />
      {/* <FeaturesTiles /> */}
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <RoadMap />
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
}

export default Home;