import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import "../../assets/css/countdown.css"
import { SETTINGS } from '../../config/config'


var tab_td1 = {
  td_1: { textAlign: 'right', paddingRight: '0px' },
};
var tab_td2 = {
  td_2: { textAlign: 'left', color: '#23a5ca', paddingLeft: '5px', fontSize: '12.5px' },
};
const CountDown = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);
  const [symbol, setSymbol] = useState('');

  const loadSymbol = async () => {
    if (!props.tokenInfo) return
    const symbol = await props.tokenInfo.methods.symbol().call()
    //console.log('token symbol', symbol)
    setSymbol(symbol)
  }


  useEffect(() => {
    loadSymbol()
  })




  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  (function () {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;



    // let time = "June 15, 2021 08:00:00";
    let time = "July 16, 2021 08:00:00";
    let countDown = new Date(time).getTime();
    const eleDay = document.getElementById("days");
    const eleHour = document.getElementById("hours");
    const eleMinutes = document.getElementById("minutes");
    const eleSeconds = document.getElementById("seconds")

    if (!eleDay || !eleHour || !eleMinutes || !eleSeconds) {
      return;
    }

    let x = setInterval(function () {

      let now = new Date().getTime(),
        distance = countDown - now;

      eleDay.innerText = Math.floor(distance / (day));

      eleHour.innerText = Math.floor((distance % (day)) / (hour));

      eleMinutes.innerText = Math.floor((distance % (hour)) / (minute));

      eleSeconds.innerText = Math.floor((distance % (minute)) / second);

      //do something later when date is reached
      if (distance < 0) {
        const container = document.getElementById("container-sm");
        container.style.display = "none";
        clearInterval(x);
      }
      //seconds
    }, 0)
  }());


  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm" id="container-sm">
        <div className="count-down">
          <div className="hero-content">
            <div class="cpn-head">
              <h2 class="title ttu">COUNTDOWN </h2>
              <div class="countdown" data-date="2021/06/30">
                <div class="countdown-item">
                  <span class="countdown-time countdown-time-first" id="days">00</span>
                  <span class="countdown-text">Days</span>
                </div>
                <div class="countdown-item">
                  <span class="countdown-time" id="hours">00</span>
                  <span class="countdown-text">Hours</span>
                </div>
                <div class="countdown-item">
                  <span class="countdown-time" id="minutes">00</span>
                  <span class="countdown-text">Min</span>
                </div><div class="countdown-item">
                  <span class="countdown-time countdown-time-last" id="seconds">00</span>
                  <span class="countdown-text">Sec</span>
                </div></div>
              <p>
                <h4>PreSale Is Living</h4>
                <center>
                  <table class="table_info">
                    <tbody>
                      <tr>
                        <td style={tab_td1.td_1}>Contract:</td>
                        <td style={tab_td2.td_2}>{SETTINGS.TOKEN_CONTRACT_ADDRESS}</td>
                      </tr>
                      <tr>
                        <td style={tab_td1.td_1}>Name:</td>
                        <td style={tab_td2.td_2}>{SETTINGS.NAME}</td>
                      </tr>
                      <tr>
                        <td style={tab_td1.td_1}>Symbol:</td>
                        <td style={tab_td2.td_2}>{symbol}</td>
                      </tr>
                      <tr>
                        <td style={tab_td1.td_1}>Decimal:</td>
                        <td style={tab_td2.td_2}>{SETTINGS.DECIMAL}</td>
                      </tr>
                    </tbody>
                  </table>
                </center>
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


export default CountDown;